import defaultSettings from '@/settings'
//获取登录路径
export function getLoginRouterPath() {
	switch (defaultSettings.loginStyle) {
		case 1:
			return import('@/views/login/index.vue')
		default:
			return import('@/views/login/indexnor.vue')
	}
}
