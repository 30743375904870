import { defineStore } from 'pinia'
import { setToken, removeToken } from '@/utils/auth'
import type { LoginParamType, UserInfoType } from '@/views/login/data'
import { getLogin, getInfo } from '@/views/login/service'
// user 为仓库的id
export const userStore = defineStore('user', {
	state: () => {
		return {
			name: '',
			avatar: '',
			roles: [],
		}
	},
	getters: {},
	actions: {
		RESET_STATE() {
			this.name = ''
			this.avatar = ''
			this.roles = []
		},
		login(userInfo: LoginParamType) {
			return new Promise((resolve, reject) => {
				getLogin({ ...userInfo })
					.then((response) => {
						const datas = response
						setToken(datas.data)
						resolve(response)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		getInfo(): Promise<UserInfoType> {
			return new Promise((resolve, reject) => {
				getInfo()
					.then((response) => {
						const { data } = response
						if (!data) {
							return reject('验证失败，请重新登录')
						}
						const { menu_list, contact_name, head_pic } = data
						this.roles = menu_list
						this.name = contact_name || ''
						this.avatar = head_pic || ''
						resolve(data)
					})
					.catch((error) => {
						reject(error)
					})
			})
		},
		resetToken() {
			return new Promise<void>((resolve) => {
				removeToken()
				this.RESET_STATE()
				resolve()
			})
		},
	},
})
