import axios, { AxiosRequestConfig } from 'axios'
import { ElMessageBox, ElMessage } from 'element-plus'
import { getToken, setToken, removeToken } from '@/utils/auth'
import EventBus from '@/utils/event'
export function getBaseUrl() {
	switch (process.env.VUE_APP_BASE_API) {
		case '/prod-api':
			return '' //生产环境url
		default:
			return process.env.VUE_APP_BASE_API
	}
}
const service = axios.create({
	baseURL: getBaseUrl(),
	timeout: 30000,
})

service.interceptors.request.use(
	(config: AxiosRequestConfig) => {
		if (getToken() && config && config.headers) {
			config.headers['token'] = getToken()
		}
		let loading = false
		if (config.params) {
			loading = config.params.loading
			// console.log(config.params)
		} else if (config.data) {
			loading = config.data.loading
			// console.log(config.data)
		}
		if (loading) {
			EventBus.emit('onLoadingMounted', true)
		}
		return config
	},
	(error) => {
		// console.log(error)
		return Promise.reject(error)
	},
)
service.interceptors.response.use(
	(response) => {
		EventBus.emit('onLoadingMounted', false)
		const res = response.data
		// console.log(response)
		if (response.headers.token && response.headers.token != getToken()) {
			setToken(response.headers.token)
		}
		if (!res.code && res instanceof Blob) {
			if (res.type === 'application/json') {
				return res
			} else {
				//下载文件
				const obj = {
					headers: response.headers,
					blob: res,
				}
				return obj
			}
		}
		const { code } = res
		if (code !== 200) {
			if (code === 300 || code === 50008 || code === 50012 || code === 50014) {
				// to re-login
				ElMessageBox.confirm(res.message, '确定退出', {
					confirmButtonText: '重新登录',
					cancelButtonText: '取消',
					type: 'warning',
				}).then(() => {
					removeToken()
					location.reload()
				})
			} else if (code == 250) {
				ElMessage.error(res.message)
			} else {
				EventBus.emit('sendHttpCode', 302)
			}
		} else {
			return res
		}

		return Promise.reject(res)
	},
	(error) => {
		EventBus.emit('onLoadingMounted', false)
		console.log('err' + error)
		ElMessage({
			message: error.message,
			type: 'error',
			duration: 5 * 1000,
		})
		return Promise.reject(error)
	},
)
export default service
