import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { getLoginRouterPath } from '@/config/setting'
/**
 * hidden: true                     如果设置为true，项目将不会显示在侧边栏中(默认为false)
 * alwaysShow: true                 如果设置为true，将始终显示根菜单
 *                                  如果没有设置alwaysShow，当item有多个子路由时，
 * 									它将变成嵌套模式，否则不显示根菜单
 * redirect: noRedirect             如果设置了noRedirect，则breadcrumb中不会有重定向
 * name:'router-name'               该名称由<keep-alive>使用(必须设置!!)
 * meta : {
    roles: ['admin','editor']       控制页面角色(可以设置多个角色)
    title: 'title'                  在侧边栏和面包屑中显示的名称(推荐设置)
    icon: 'svg-name'/'el-icon-x' 	图标显示在侧边栏中,*注意这里全部使用阿里图标库icon
    noCache: true                   如果设置为true，页面将不会被缓存(默认为false)
    affix: true                     如果设置为true，标签将被添加到标签视图中
    breadcrumb: false            	如果设置为false，项目将隐藏在breadcrumb中(默认为true)
    activeMenu: '/example/list'     如果设置了path，侧边栏将突出显示您设置的路径
  }
 */
declare module 'vue-router' {
	interface _RouteRecordBase {
		hidden?: boolean | string | number
		alwaysShow?: boolean
		fullPath?: string
	}
}

import Layout from '@/layout/index.vue'
// 基础路由
export const constantRoutes: Array<RouteRecordRaw> = [
	{
		path: '/redirect',
		component: Layout,
		hidden: true,
		children: [
			{
				path: '/redirect/:path*',
				component: () => import('@/views/redirect/index.vue'),
			},
		],
	},
	{
		path: '/404',
		component: () => import('@/views/404.vue'),
		hidden: true,
	},
	{
		path: '/302',
		redirect: '/302/index',
		component: Layout,
		hidden: true,
		children: [
			{
				path: 'index',
				name: '302',
				component: () => import('@/views/302.vue'),
			},
		],
	},
	{
		path: '/changePassword',
		redirect: '/changePassword/index',
		component: Layout,
		hidden: true,
		children: [
			{
				path: 'index',
				name: 'changePassword',
				meta: {
					title: '修改密码',
					noCache: true,
				},
				component: () =>
					import('@/views/rolepermission/changePassword/index.vue'),
			},
		],
	},
	// 首页路径和名称请不要修改
	{
		path: '/',
		redirect: '/dashboard',
		component: Layout,
		children: [
			{
				path: 'dashboard', //不要修改
				name: 'Dashboard', //不要修改
				component: () => import('@/views/dashboard/index.vue'),
			},
		],
	},
	{
		path: '/login',
		component: () => getLoginRouterPath(),
		hidden: true,
	},
]
/**
 * 动态路由
 */
export const asyncRoutes: Array<RouteRecordRaw> = [
	{
		path: '/rolepermission',
		component: Layout,
		redirect: '/rolepermission/accountmanage/index',
		meta: {
			title: '系统设置',
			noCache: true,
			roles: ['1409769510adda7e2f32e6d159131c32'],
			icon: 'icon-xitongguanli',
		},
		children: [
			{
				path: 'accountmanage/index',
				name: 'AccountManage',
				component: () =>
					import('@/views/rolepermission/accountmanage/index.vue'),
				meta: {
					title: '账号管理',
					noCache: true,
					roles: ['bffcfa41c6ea502ff551454ad7ca9542'],
					icon: 'icon-yonghuguanli',
				},
			},
			{
				path: 'rolemanage/index',
				name: 'RoleManage',
				component: () => import('@/views/rolepermission/rolemanage/index.vue'),
				meta: {
					title: '角色管理',
					noCache: true,
					roles: ['178d87b79220734ec6efc6e11ba605e2'],
					icon: 'icon-xitongguanli_jiaoseguanli',
				},
			},
			{
				path: 'addrole/index',
				name: 'AddRole',
				hidden: true,
				component: () =>
					import('@/views/rolepermission/rolemanage/addrole/index.vue'),
				meta: {
					noCache: true,
					activeMenu: '/rolepermission/rolemanage/index',
					title: '添加角色',
					icon: 'icon-dingdanguanli',
					roles: ['21e4867b68d134d5e77e84aee6616b7d'],
				},
			},
			{
				path: 'addaccount/index',
				name: 'AddAccount',
				hidden: true,
				component: () =>
					import('@/views/rolepermission/accountmanage/addaccount/index.vue'),
				meta: {
					noCache: true,
					activeMenu: '/rolepermission/accountmanage/index',
					title: '添加账号',
					roles: ['086117566c2c4f08fc1520d03fdfb452'],
				},
			},
			{
				path: 'beilv/index',
				name: 'beilv',
				component: () => import('@/views/rolepermission/beilv/index.vue'),
				meta: {
					title: '价格倍率管理',
					noCache: true,
					icon: 'icon-xitongguanli',
					roles: ['4f12dd5ece7ac19824d5b4de539fa8ae'],
				},
			},
			{
				path: 'integral/index',
				name: 'Integral',
				component: () => import('@/views/rolepermission/Integral/index.vue'),
				meta: {
					title: '积分规则管理',
					noCache: true,
					roles: ['a662c5d68e501df455e616f03950fd3a'],
					icon: 'icon-jifen01',
				},
			},
		],
	},
	{
		path: '/shopmange',
		component: Layout,
		redirect: '/shopmange/choosegoodsmarket',
		meta: {
			noCache: false,
			title: '商品管理',
			roles: ['5782b6eac4b724022c1c68499a6e342d'],
			icon: 'icon-shangpin',
		},
		children: [
			{
				path: 'choosegoodsmarket',
				name: 'ChooseGoodsMarket',
				component: () =>
					import('@/views/ShopManage/ChooseGoodsMarket/index.vue'),
				meta: {
					noCache: false,
					title: '选货市场',
					icon: 'icon-pifashichang',
					roles: ['802e8f3663d41e16e19a8bcff45eb7a7'],
				},
			},
			{
				path: 'choosegoodsmarketdetail',
				name: 'ChooseGoodsMarketDetail',
				hidden: true,
				component: () =>
					import(
						'@/views/ShopManage/ChooseGoodsMarket/components/FineFooddetail/index.vue'
					),
				meta: {
					activeMenu: '/shopmange/choosegoodsmarket',
					noCache: true,
					title: '美食详情',
					icon: 'icon-yemian',
				},
			},
			{
				path: 'coupondetail',
				name: 'Coupondetail',
				hidden: true,
				component: () =>
					import(
						'@/views/ShopManage/ChooseGoodsMarket/components/Coupondetail/detail.vue'
					),
				meta: {
					activeMenu: '/shopmange/choosegoodsmarket',
					noCache: true,
					title: '卡券详情',
					icon: 'icon-yemian',
				},
			},
			{
				path: 'couponSondetailPage',
				name: 'CouponSondetailPage',
				hidden: true,
				component: () =>
					import(
						'@/views/ShopManage/ChooseGoodsMarket/components/Coupondetail/detailbrand.vue'
					),
				meta: {
					activeMenu: '/shopmange/choosegoodsmarket',
					noCache: true,
					title: '大牌卡券子详情',
					icon: 'icon-pifashichang',
				},
			},
			{
				path: 'goodslist',
				name: 'GoodsList',
				component: () => import('@/views/ShopManage/GoodsList/index.vue'),
				meta: {
					noCache: false,
					title: '商品列表',
					roles: ['64daca0e6932b28c6ab32ee65907c8a0'],
					icon: 'icon-shangpinliebiao',
				},
			},
			{
				path: 'hotstylezone',
				name: 'HotStyleZone',
				component: () => import('@/views/ShopManage/HotStyleZone/index.vue'),
				meta: {
					noCache: false,
					title: '爆款专区',
					roles: ['55a99697e043de78b96eea8dd2bf57aa'],
					icon: 'icon-baokuan-',
				},
			},
			{
				path: 'goodsimport/index',
				name: 'HotStyleZoneGoodImport',
				hidden: true,
				component: () => import('@/views/GoodsImport/index.vue'),
				meta: {
					noCache: false,
					title: '导入商品',
					activeMenu: '/shopmange/hotstylezone',
					icon: 'icon-gongneng',
				},
			},
			{
				path: 'icbcPromote',
				name: 'IcbcPromote',
				component: () => import('@/views/ShopManage/IcbcPromote/index.vue'),
				meta: {
					noCache: false,
					title: '工行推广',
					roles: ['55a99697e043de78b96eea8dd2bf57aa'],
					icon: 'icon-baokuan-',
				},
			},
			{
				path: 'goodsimporticbc/index',
				name: 'IcbcPromoteGoodImport',
				hidden: true,
				component: () => import('@/views/GoodsImportIcbc/index.vue'),
				meta: {
					noCache: false,
					title: '导入商品',
					activeMenu: '/shopmange/icbcPromote',
					icon: 'icon-gongneng',
				},
			},
			{
				path: 'icbcPromoteInfo/index',
				name: 'IcbcPromoteInfo',
				hidden: true,
				component: () =>
					import('@/views/ShopManage/IcbcPromote/info/index.vue'),
				meta: {
					noCache: false,
					title: '首页活动说明',
					activeMenu: '/shopmange/icbcPromote',
					icon: 'icon-gongneng',
				},
			},
		],
	},
	{
		path: '/usermanage',
		redirect: '/usermanage/index',
		component: Layout,
		meta: {
			noCache: false,
			roles: ['a22f30d30839005c3dba903289215bd9'],
		},
		children: [
			{
				path: 'index',
				name: 'UserManage',
				component: () => import('@/views/UserManage/index.vue'),
				meta: {
					noCache: false,
					title: '用户管理',
					icon: 'icon-yonghuguanli',
					roles: ['a22f30d30839005c3dba903289215bd9'],
				},
			},
			{
				path: 'detail',
				name: 'UserManageDetail',
				component: () => import('@/views/UserManage/detail/detail.vue'),
				hidden: true,
				meta: {
					activeMenu: '/usermanage/index',
					noCache: true,
					title: '用户管理详情',
				},
			},
		],
	},
	{
		path: '/ordermanage',
		redirect: '/ordermanage/storefood',
		component: Layout,
		meta: {
			noCache: false,
			icon: 'icon-dingdanguanli',
			title: '订单管理',
			roles: ['712ee87c76e9d99cabab7d1bcb74e3ab'],
		},
		children: [
			{
				path: 'storefood',
				name: 'StoreFood',
				component: () => import('@/views/OrderManage/StoreFood/index.vue'),
				meta: {
					noCache: false,
					title: '订单管理',
					icon: 'icon-dingdanguanli',
					roles: ['712ee87c76e9d99cabab7d1bcb74e3ab'],
				},
			},
			{
				path: 'storefooddetail',
				name: 'StoreFoodDetail',
				component: () => import('@/views/OrderManage/StoreFood/detail.vue'),
				hidden: true,
				meta: {
					activeMenu: '/ordermanage/storefood',
					noCache: true,
					title: '订单详情',
					icon: 'icon-meishi',
				},
			},
		],
	},
	// 热门专栏管理
	{
		path: '/hotcolumn',
		redirect: '/hotcolumn/specialcloumn/index',
		component: Layout,
		meta: {
			noCache: true,
			title: '热门专栏管理',
			roles: ['bd89a0d1f0b2fda705e4acaa621e81f6'],
			icon: 'icon-shangpin',
		},
		children: [
			{
				path: 'specialcloumn/index',
				name: 'specialcloumn',
				component: () => import('@/views/hotcolumn/specialcloumn/index.vue'),
				meta: {
					noCache: false,
					title: '热门专栏列表',
					roles: ['bd89a0d1f0b2fda705e4acaa621e81f6'],
					icon: 'icon-shangpin',
				},
			},
			{
				path: 'specialcloumn/edit',
				name: 'specialcloumnEdit',
				hidden: true,
				component: () => import('@/views/hotcolumn/specialcloumn/edit.vue'),
				meta: {
					noCache: true,
					activeMenu: '/hotcolumn/specialcloumn/index',
					title: '热门专栏新增/编辑',
					icon: 'icon-shangpin',
				},
			},
			{
				path: 'settinggoods/index',
				name: 'settinggoods',
				hidden: true,
				component: () =>
					import('@/views/hotcolumn/specialcloumn/settinggoods.vue'),
				meta: {
					noCache: false,
					title: '配置商品',
					activeMenu: '/hotcolumn/specialcloumn/index',
					icon: 'icon-gongneng',
				},
			},
			{
				path: 'goodsimport/index',
				name: 'HotcolumnGoodImport',
				hidden: true,
				component: () => import('@/views/GoodsImport/index.vue'),
				meta: {
					noCache: false,
					title: '导入商品',
					activeMenu: '/hotcolumn/specialcloumn/index',
					icon: 'icon-gongneng',
				},
			},
		],
	},
	{
		path: '/jingangqu',
		redirect: '/jingangqu/index',
		component: Layout,
		meta: {
			noCache: false,
			roles: ['bfeed5f015ea0e85dcd124f7c0842f94'],
		},
		children: [
			{
				path: 'index',
				name: 'Jingangqu',
				component: () => import('@/views/Jingangqu/index.vue'),
				meta: {
					noCache: false,
					title: '金刚区管理',
					icon: 'icon-xitongguanli',
					roles: ['bfeed5f015ea0e85dcd124f7c0842f94'],
				},
			},
			{
				path: 'edit',
				name: 'JingangquEdit',
				component: () => import('@/views/Jingangqu/edit.vue'),
				hidden: true,
				meta: {
					activeMenu: '/jingangqu/index',
					noCache: true,
					title: '金刚区管理编辑',
					icon: 'icon-caiwu',
				},
			},
		],
	},
	{
		path: '/bannermanage',
		redirect: '/bannermanage/index',
		component: Layout,
		meta: {
			noCache: false,
			roles: ['afb5af344bedf8474fa9097d1def0a1a'],
		},
		children: [
			{
				path: 'index',
				name: 'BannerManage',
				component: () => import('@/views/BannerManage/index.vue'),
				meta: {
					noCache: false,
					title: '横幅管理',
					icon: 'icon-bannershezhi',
					roles: ['afb5af344bedf8474fa9097d1def0a1a'],
				},
			},
			{
				path: 'edit',
				name: 'banneredit',
				component: () => import('@/views/BannerManage/edit/index.vue'),
				hidden: true,
				meta: {
					activeMenu: '/bannermanage/index',
					noCache: true,
					title: '横幅编辑',
					icon: 'icon-bannershezhi',
				},
			},
		],
	},
	{
		path: '/financialmanage',
		redirect: '/financialmanage/index',
		component: Layout,
		meta: {
			noCache: false,
			title: '财务管理',
			roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
		},
		children: [
			{
				path: 'index',
				name: 'FinancialManage',
				component: () => import('@/views/FinancialManage/index.vue'),
				meta: {
					noCache: false,
					title: '财务管理',
					icon: 'icon-caiwu',
					roles: ['152fca3361aaeabf85b8dc7fe24db01a'],
				},
			},
		],
	},

	{
		path: '/category',
		redirect: '/category/index',
		component: Layout,
		meta: {
			noCache: false,
			roles: ['afb5af344bedf8474fa9097d1def0a1a'],
		},
		children: [
			{
				path: 'index',
				name: 'CategoryList',
				component: () => import('@/views/Category/index.vue'),
				meta: {
					noCache: false,
					title: '分类管理',
					icon: 'icon-bannershezhi',
					roles: ['afb5af344bedf8474fa9097d1def0a1a'],
				},
			},
			{
				path: 'edit',
				name: 'CategoryDetail',
				component: () => import('@/views/Category/edit.vue'),
				hidden: true,
				meta: {
					activeMenu: '/category/index',
					noCache: true,
					title: '分类编辑',
					icon: 'icon-bannershezhi',
				},
			},
		],
	},

	//匹配所有路径 使用 /:pathMatch(.*)*或/:pathMatch(.*)或/:catchAll(.*)
	{ path: '/:catchAll(.*)', redirect: '/404', hidden: true },
]

const createNewRouter = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	// history: createWebHistory(process.env.BASE_URL),
	routes: constantRoutes.concat(asyncRoutes),
})

const router = createNewRouter

export function resetRouter() {
	router.getRoutes().forEach((route) => {
		const { name } = route
		if (name) {
			router.hasRoute(name) && router.removeRoute(name)
		}
	})
}
export default router
