<template>
	<div class="layout-container">
		<div>我是综合</div>
		<div>
			<router-view />
		</div>
	</div>
</template>

<script lang="ts" setup></script>
<style lang="scss" scoped></style>
