import router, { constantRoutes, resetRouter } from './router'
import { getToken, removeToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
	easing: 'ease', // 动画方式
	speed: 500, // 递增进度条的速度
	showSpinner: false, // 是否显示加载ico
	trickleSpeed: 200, // 自动递增间隔
	minimum: 0.3, // 初始化时的最小百分比
})
import getPageTitle from './utils/get-page-title'
import pinia from './store/index'
import { permissionStore } from './store/module/permission'
import { userStore } from './store/module/user'
import { storeToRefs } from 'pinia'
const permission = permissionStore(pinia)
const user = userStore(pinia)
const { routes } = storeToRefs(permission)
const whiteList = ['/login'] //没有重定向白名单
router.beforeEach(async (to, from, next) => {
	//页面加载进度条
	NProgress.start()
	//设置页面标题
	document.title = getPageTitle(to.meta.title)
	//判断用户是否已登录
	const hasToken = getToken()
	if (hasToken) {
		if (to.path === '/login') {
			//如果已登录，则重定向到主页
			next({ path: '/' })
			NProgress.done()
		} else {
			const hasRoles = routes.value && routes.value.length > 0
			if (hasRoles && routes.value) {
				next()
			} else {
				try {
					const { menu_list } = await user.getInfo()
					let roleList: string[] = []
					getChildRoles(roleList, menu_list)
					if (roleList.length === 0) {
						roleList = ['-1']
					}
					const accessRoutes = await permission.generateRoutes(roleList)
					router.options.routes = constantRoutes.concat(accessRoutes)
					for (const path of accessRoutes) {
						router.addRoute(path)
					}
					next({ ...to, replace: true })
				} catch (error) {
					removeToken()
					resetRouter()
					ElMessage.error((error as string) || 'Has Error')
					next(`/login?redirect=${to.path}`)
					NProgress.done()
				}
			}
		}
	} else {
		//没有token
		if (whiteList.indexOf(to.path) !== -1) {
			// 在免费登录白名单中，直接进入
			next()
		} else {
			// 其他没有访问权限的页面被重定向到登录页面。
			next(`/login?redirect=${to.path}`)
			NProgress.done()
		}
	}
})
router.afterEach(() => {
	NProgress.done()
})
interface arr {
	id: string
	name: string
	children?: arr[]
}
export function getChildRoles(roleList: string[], menu_list: arr[]) {
	menu_list.forEach((role) => {
		if (role.id) {
			if (role.children) {
				getChildRoles(roleList, role.children)
			}
			roleList.push(role.id)
		}
	})
}
