import defaultSettings from '@/settings'

const title = defaultSettings.title || '御卡商城后台管理'

export default function getPageTitle(pageTitle: string | unknown) {
	if (pageTitle) {
		return `${pageTitle} - ${title}`
	}
	return `${title}`
}
