import { defineStore } from 'pinia'
import type { RouteLocationNormalizedLoaded } from 'vue-router'
export type RouteType = {
	fullPath: string
	path: string
	name: string
	title?: string
	query?: object
	cache?: boolean
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	meta?: any
}

export type DelViewType = {
	visitedViews: Array<RouteType>
	cachedViews: string[]
}
// tagsView 为仓库的id
export const drawingStore = defineStore('drawing', {
	state: () => {
		return {
			visitedViews: [] as Array<RouteType>,
			cachedViews: [] as string[],
		}
	},
	getters: {},
	actions: {
		updataDrawing(viewArr: RouteType[]) {
			this.visitedViews = viewArr
			this.setCachedViews()
		},
		delSomeDrawing(routePath: string) {
			let ind = 0
			this.visitedViews.find((item, index) => {
				if (item.path == routePath) {
					ind = index
				}
			})
			this.visitedViews.splice(ind, 1)
		},
		setCachedViews() {
			this.cachedViews = []
			this.visitedViews.map((item) => {
				if (!item.cache) {
					this.cachedViews.push(item.name)
				}
			})
		},
		// //添加view到显示和缓存数组
		// addView(view: RouteType) {
		// 	this.addVisitedView(view)
		// 	this.addCachedView(view)
		// },
		// //添加view到tag显示数组
		// addVisitedView(view: RouteType) {
		// 	if (this.visitedViews.some((v) => v.path === view.path)) return
		// 	//必须配置名称
		// 	if (!view.meta.title) return
		// 	this.visitedViews.push(
		// 		Object.assign({}, view, { title: view.meta.title }),
		// 	)
		// },
		// //添加view名称到tag缓存数组
		// addCachedView(view: RouteType) {
		// 	if (this.cachedViews.includes(view.name as string)) return
		// 	if (!view.meta.noCache) {
		// 		this.cachedViews.push(view.name)
		// 	}
		// },
		// //删除缓存页
		// delCachedView(view: RouteType) {
		// 	return new Promise((resolve) => {
		// 		const index = this.cachedViews.indexOf(view.name)
		// 		index > -1 && this.cachedViews.splice(index, 1)
		// 		resolve([...this.cachedViews])
		// 	})
		// },
		// // 删除显示tag
		// delVisitedView(view: RouteType) {
		// 	return new Promise((resolve) => {
		// 		for (const [i, v] of this.visitedViews.entries()) {
		// 			if (v.path === view.path) {
		// 				this.visitedViews.splice(i, 1)
		// 				break
		// 			}
		// 		}
		// 		resolve([...this.visitedViews])
		// 	})
		// },
		// //删除所有当前tag，包括显示和缓存的tag
		// delView(view: RouteType) {
		// 	return new Promise((resolve) => {
		// 		this.delVisitedView(view)
		// 		this.delCachedView(view)
		// 		resolve({
		// 			visitedViews: [...this.visitedViews],
		// 			cachedViews: [...this.cachedViews],
		// 		})
		// 	}) as Promise<DelViewType>
		// },
		// //删除其他显示的tag，保留当前tag
		// delOthersVisitedViews(view: RouteType) {
		// 	return new Promise((resolve) => {
		// 		this.visitedViews = this.visitedViews.filter((v) => {
		// 			return v.meta.affix || v.path === view.path
		// 		})
		// 		resolve([...this.visitedViews])
		// 	})
		// },
		// //删除其他缓存的tag，保留当前tag
		// delOthersCachedViews(view: RouteType) {
		// 	const index = this.cachedViews.indexOf(view.name)
		// 	if (index > -1) {
		// 		this.cachedViews = this.cachedViews.slice(index, index + 1)
		// 	} else {
		// 		this.cachedViews = []
		// 	}
		// 	return new Promise((resolve) => {
		// 		resolve([...this.cachedViews])
		// 	})
		// },
		// //删除其他tag
		// delOthersViews(view: RouteType) {
		// 	return new Promise((resolve) => {
		// 		this.delOthersVisitedViews(view)
		// 		this.delOthersCachedViews(view)
		// 		resolve({
		// 			visitedViews: [...this.visitedViews],
		// 			cachedViews: [...this.cachedViews],
		// 		})
		// 	}) as Promise<DelViewType>
		// },
		// //删除所有显示的tag
		// delAllVisitedViews() {
		// 	const affixTags = this.visitedViews.filter((tag) => tag.meta.affix)
		// 	this.visitedViews = affixTags
		// },
		// //删除所有缓存的tag
		// delAllCachedViews() {
		// 	this.cachedViews = []
		// },
		// //删除所有tag
		// delAllViews() {
		// 	return new Promise((resolve) => {
		// 		this.delAllVisitedViews()
		// 		this.delAllCachedViews()
		// 		resolve({
		// 			visitedViews: [...this.visitedViews],
		// 			cachedViews: [...this.cachedViews],
		// 		})
		// 	}) as Promise<DelViewType>
		// },
		// //更新当前tag
		// updateVisitedView(view: RouteLocationNormalizedLoaded) {
		// 	for (let v of this.visitedViews) {
		// 		if (v.path === view.path) {
		// 			v = Object.assign(v, view)
		// 			break
		// 		}
		// 	}
		// },
	},
})
