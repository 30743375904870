export default {
	title: '御卡商城后台管理系统',
	layout: 'portrait', //portrait:纵向，landscape：横向，subfield：分栏，synthesize：综合
	// menuBg: '#ffffff', //导航栏背景颜色
	menuBg: '#ffffff', //导航栏背景颜色
	// menuActiveBg: '#E8F4ff', //导航栏选中item背景颜色
	menuActiveBg: '#E8F4ff', //导航栏选中item背景颜色
	// menuText: '#515a6e', //导航栏字体颜色
	menuText: '#515a6e', //导航栏字体颜色
	// menuActiveText: '#1890ff', //导航栏选中后字体颜色
	menuActiveText: '#1890ff', //导航栏选中后字体颜色
	menuWidth: '266px', //侧边栏宽度
	menuScrollBarColor: '#909399', //侧边栏scrollbar颜色
	lockBgImageArr: [
		'url(' +
			require('@/assets/lock/lock_bg_001.jpeg') +
			') center center / 100% 100% fixed',
		'url(' +
			require('@/assets/lock/lock_bg_002.jpeg') +
			') center center / 100% 100% fixed',
		'url(' +
			require('@/assets/lock/lock_bg_003.jpeg') +
			') center center / 100% 100% fixed',
		'url(' +
			require('@/assets/lock/lock_bg_004.jpeg') +
			') center center / 100% 100% fixed',
	], //锁屏背景图片
	lockTime: 3 * 60, //闲置多长时间锁屏,单位s，小于60表示不自动锁屏
	loginStyle: 0, //0：为普通登录页面，1：科技感登录页面
	loadingActive: 2, // 网络加载中动画 有1.2.3.4四种不同效果
}
